body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pulsating-opacity {
  animation: pulsateOpacity 2s infinite;
}

@keyframes pulsateOpacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.list-item:hover {
  opacity: 0.7;
  cursor: pointer;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller.alt div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #224984;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nice-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.nice-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 14px;
}

.nice-scrollbar::-webkit-scrollbar-thumb {
  background: silver;
  border-radius: 6px;
}

.nice-scrollbar {
  scrollbar-color: silver #f1f1f1;
}

.editor-text ul {
  list-style: inherit;
  padding-inline-start: 10px;
}

.pointer {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}

.custom-scroll-sm {
  scrollbar-width: thin;
  scrollbar-color: lightgrey white;
}
.custom-scroll-sm ::-webkit-scrollbar {
  width: 8px;
  background: white;
  cursor: pointer;
}

.custom-scroll-sm ::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 8px;
  cursor: pointer;
}

.custom-scroll-sm ::-webkit-scrollbar-thumb:hover {
  background: grey;
  cursor: pointer;
}

.custom-scroll-sm ::-webkit-scrollbar-track {
  background: white;
  border-radius: 8px;
  cursor: pointer;
}
