.policy-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 1em;
  gap: 1em;
}
.cancellation-download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #224984;
  border: 1px solid #224984;
  color: #ffffff;
  align-self: center;
  padding: 0.5em 2em;
  border-radius: 4px;
}

.cancellation-download-button:focus {
  outline: none;
}
.cancellation-download-button:hover {
  background-color: #fff;
  border: 1px solid #187094;
  color: #187094;
}
.pdf-viewer .rpv-core__viewer {
  box-shadow: none !important;
  border: none;
}
