.login-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
}
.login-input-label {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0px;
  word-wrap: break-word;
}
.input-items-container {
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 12px;
  gap: 4px;
  /* min-width: 300px; */
}
.input-items-prefix {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.login-input {
  display: flex;
  flex: 1;
  border: none;
  background-color: transparent;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.login-input:focus {
  outline: none;
}
.show-password-icon {
  cursor: pointer;
}
.login-from-helper-text {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: red;
  margin: 0px;
  max-width: 300px;
}
.login-info-tooltip {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  text-align: center;
  width: 200px;
}
.label-tooltip-container {
  display: flex;
  align-items: center;
  gap: 1em;
}
