.onboarding-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  flex-direction: column;
}
.onboarding-title {
  font-family: Lato;
  font-size: 31px;
  font-weight: 400;
  line-height: 37.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
.onboarding-subtitle {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
.onboarding-content-container {
  display: flex;
  flex: 1;
  gap: 2em;
}
.onboarding-content-navigation {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 32px;
  box-shadow: 1px 3px 18px 0px #0000001a;
  gap: 8px;
  max-height: min-content;
  background-color: #fff;
  position: fixed;
  left: 5%;
}
.onboarding-content-forms {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2em;
  border-radius: 32px;
  box-shadow: 1px 3px 18px 0px #0000001a;
  gap: 1em;
  max-height: min-content;
  max-width: 80vw;
  background-color: #fff;
}

@media (max-width: 1100px) {
  .onboarding-content-container {
    flex-direction: column;
  }
  .onboarding-content-navigation {
    position: relative;
    left: 0;
    align-self: flex-start;
  }
}
.onboarding-navigation-label {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  margin: 0px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.onboarding-navigation-label:hover {
  transform: scale(1.1);
}
.onboarding-section-container {
  display: flex;
  flex-direction: column;
}
.onboarding-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}
.onboarding-section-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #808080;
  margin: 1em 0px;
}
.onboarding-divider {
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #e5e5e5;
}
.onboarding-horizontal-input-container {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
  margin: 1em 0px;
}
@media (max-width: 650px) {
  .onboarding-horizontal-input-container {
    flex-direction: column;
  }
}

.onboarding-profile-pic-container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.onboarding-profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  display: flex;
  align-self: center;
  object-fit: contain;
}
.onboarding-upload-btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 12px 6px;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #3f77cd;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f77cd;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #fff;
}
.onboarding-upload-btn:hover {
  transform: scale(1.02);
  font-weight: 700;
}
.onboarding-specification-warning {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  margin: 0px;
}
.onboarding-save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #224984;
  color: #ffffff;
  flex: 1;
  border: 1px solid #187094;
}
.onboarding-save-btn:focus {
  outline: none;
}
.onboarding-save-btn:hover {
  background-color: #fff;
  color: #187094;
}
