.benefits-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  justify-items: center;
  align-items: start;
  align-self: center;
  transition: all 0.5s ease;
}
@media (max-width: 1100px) {
  .benefits-layout {
    grid-template-columns: 1fr;
  }
}
.left-section {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.benefits-image {
  object-fit: cover;
  max-width: 550px;
  max-height: 795px;
  align-self: flex-start;
}
.benefits-top-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  text-align: left;
  align-self: flex-start;
}
.items-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.benefits-item-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 32px;
  background-color: #e9f7fc;
  padding: 1em;
}
.benefits-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #3f77cd;
  color: #fff;
  align-self: flex-start;
  border-radius: 22px;
}
.benefits-item-title {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  line-height: 34px;
  color: #000;
}
.benefits-item-description {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  line-height: 19px;
  color: #000;
}
@media (max-width: 550px) {
  .benefits-image {
    object-fit: contain;
    max-width: 350px;
    max-height: 595px;
    align-self: flex-start;
  }
}
