.od-card-container {
  padding: 24px;
  gap: 10px;
  background-color: #fff;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 8px;
}
.od-profile-pic-container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.od-profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  display: flex;
  align-self: center;
  /* object-fit: cover; */
}
.od-upload-btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 12px 6px;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #3f77cd;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f77cd;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #fff;
}
.od-upload-btn:hover {
  transform: scale(1.02);
  font-weight: 700;
}
.od-horizontal-input-container {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
  /* margin: 1em 0px; */
}

.od-checkbox-container {
  display: flex;
  align-items: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  cursor: pointer;
}
.od-subtext {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #808080;
  margin: 0px 34px;
}
.od-checkbox {
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 2px solid #3f77cd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.od-checkbox:checked {
  background-color: #fff;
  border-color: #3f77cd;
}

.od-checkbox:checked::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #3f77cd;
  border-radius: 2px;
  display: inline-block;
  clip-path: polygon(14% 44%, 0% 59%, 47% 100%, 100% 15%, 84% 0%, 40% 75%);
}
.od-label {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}
.ql-editor {
  min-height: 70px !important;
}
.od-bold-label {
  font-weight: 700 !important;
  color: #3f77cd !important;
}
