@import 'zeplin-base.scss';

.Card {
  background-color: #FFF;
  border: 2px solid $dark;
  border-radius: 8px;
  transition: .3s ease;
  &.--hoverable {
    cursor: pointer;

    &:hover {
      opacity: .75;
    }
  }
  &.Shadow {
    border: none;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.4);
    &:hover {
      box-shadow: 2px 4px 6px rgba(30,30,30,0.4);
    }
  }
  &.--selectable {
    cursor: pointer;

    &.--selected {
      border-color: $light;
      box-shadow: $standard-box-shadow;

    }
  }
}
