/* MAIN CREDIT CARD CONTAINER */

.credit-card {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 7px;
    width: 95%;
    max-width: 250px;
    position: relative;
    transition: all 0.4s ease;
    box-shadow: 0 2px 4px 0 #cfd7df;
    min-height: 60px;
    padding: 13px;
    background: #1a1b1c;
    color: #efefef;
}
.credit-card,
.credit-card * {
   box-sizing: content-box;
}

.credit-card.selectable:hover {
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}


/*  NUMBER FORMATTING */

.credit-card-last4 {
    font-family: "PT Mono", Helvetica, sans-serif;
    font-size: 18px;
}

.credit-card-last4:before {
    content: "**** **** **** ";
    color: #4f4d4d;
    font-size: 18px;
}

.credit-card.american-express .credit-card-last4:before,
.credit-card.amex .credit-card-last4:before {
    content: "**** ****** *";
}

.credit-card.diners-club .credit-card-last4:before,
.credit-card.diners .credit-card-last4:before {
    content: "**** ****** ";
}

.credit-card-expiry {
    font-family: "PT Mono", Helvetica, sans-serif;
    font-size: 16px;
    position: absolute;
    bottom: 8px;
    left: 15px;
    margin-top: 10px;
}


/* BRAND CUSTOMIZATION */

.credit-card.visa {
    background: #4862e2;
    color: #eaeef2;
}

.credit-card.visa .credit-card-last4:before {
    color: #8999e5;
}

.credit-card.mastercard {
    background: #4f0cd6;
    color: #e3e8ef;
}

.credit-card.mastercard .credit-card-last4:before {
    color: #8a82dd;
}

.credit-card.american-express,
.credit-card.amex {
    background: #1cd8b3;
    color: #f2fcfa;
}

.credit-card.american-express .credit-card-last4:before,
.credit-card.amex .credit-card-last4:before {
    color: #99efe0;
}

.credit-card.diners, .credit-card.diners-club {
      background: #8a38ff;
      color: #f5efff;
    }

    .credit-card.diners .credit-card-last4:before, .credit-card.diners-club .credit-card-last4:before {
      color: #b284f4;
    }

.credit-card.discover {
    background: #f16821;
    color: #fff4ef;
}

.credit-card.discover .credit-card-last4:before {
    color: #ffae84;
}

.credit-card.jcb {
    background: #cc3737;
    color: #f7e8e8;
}

.credit-card.jcb .credit-card-last4:before {
    color: #f28a8a;
}

.credit-card.unionpay {
    background: #47bfff;
    color: #fafdff;
}

.credit-card.unionpay .credit-card-last4:before {
    color: #99dcff;
}


/*   LOGOS  */

.credit-card::after {
    content: " ";
    position: absolute;
    bottom: 10px;
    right: 15px;
}

.credit-card.visa::after {
    height: 16px;
    width: 50px;
    background-image: url("../../../images/credit-card-backgorund/visa.png");
}

.credit-card.mastercard::after {
    width: 40px;
    height: 25px;
    background-image: url("../../../images/credit-card-backgorund/mastercard.png");
}

.credit-card.amex::after,
.credit-card.american-express::after {
    width: 50px;
    height: 14px;
    background-image: url("../../../images/credit-card-backgorund/amex.png");
}

.credit-card.diners::after,
.credit-card.diners-club::after {
    width: 30px;
    height: 24px;
    background-image: url("../../../images/credit-card-backgorund/diners.png");
}

.credit-card.discover::after {
    width: 50px;
    height: 14px;
    background-image: url("../../../images/credit-card-backgorund/discover.png");
}

.credit-card.jcb::after {
    width: 30px;
    height: 15px;
    background-image: url("../../../images/credit-card-backgorund/jsb.png");
}

.credit-card.unionpay::after {
    width: 50px;
    height: 30px;
    background-image: url("../../../images/credit-card-backgorund/unionpay.png");
}

.editcard
{
    margin-left: 280px;
    color: #3B5998;
}