.step1_card {
  padding: 30px;
}
.register-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 2em 2em 0em 2em;
}
.myrep-logo-top {
  height: 238px;
  width: 238px;
  object-fit: contain;
}
.register-content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.register-heading {
  font-family: Lato;
  font-size: 31px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
  color: #000;
}
.register-box-container {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
}
@media (max-width: 800px) {
  .register-box-container {
    flex-direction: column;
  }
  .register-main-container {
    padding: 0px 8px;
  }
  .register-content-container {
    margin: 8px 0px;
  }
}
.registration-box-container {
  padding: 31px 36px 31px 36px;
  gap: 36px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 3px 18px 0px #0000001a;
  flex: 1;
  height: 100%;
  max-width: 500px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.registration-box-container:hover {
  box-shadow: 10px 3px 18px 10px #0000001a;
}
.registration-box-icon {
  height: 58px;
  width: 58px;
}
.registration-box-title-container {
  display: flex;
  gap: 1em;
  align-items: center;
}
.registration-box-title {
  font-family: Lato;
  font-size: 31px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
}
.registration-box-subtext {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
}
