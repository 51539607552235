.hiw-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  align-self: center;
  text-align: center;
}
.video-player-container {
  max-width: 60vw;
  display: flex;
  align-self: center;
  border-radius: 32px;
  overflow: hidden;
}
@media (max-width: 900px) {
  .video-player-container {
    max-width: 90vw;
  }
}
