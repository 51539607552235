.testimonial-container {
  background-color: #224984;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  justify-items: center;
  align-items: start;
  transition: all 0.5s ease;
  padding: 2em;
  border-radius: 35px;
  min-height: 500px;
  max-width: 1280px;
  align-self: center;
}
@media (max-width: 1100px) {
  .testimonial-container {
    grid-template-columns: 1fr;
  }
}
.testimonial-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  padding: 0px 1em;
  gap: 1em;
  height: 100%;
  justify-content: center;
}
.testimonial-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  padding: 0px 1em;
  gap: 1em;
  height: 100%;
  justify-content: center;
}
.h-testimonial-top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.testimonial-pill {
  padding: 12px 24px;
  border-radius: 32px;
  background: rgba(37, 170, 225, 0.2);
  backdrop-filter: blur(10px);
  color: #25aae1;
}
.t-title-text {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  margin: 0px;
  color: #fff;
}
.t-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  align-self: flex-start;
}
.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #fff;
}
.arrow-button:focus {
  outline: none;
}
.arrow-button:hover {
  filter: brightness(0.8);
}
.arrow-button:disabled {
  cursor: no-drop;
}
.review-wrapper {
  overflow: hidden;
  width: 100%;
}
.review-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 100px 2em 1em 2em;
  color: #fff;
  background: rgba(37, 170, 225, 0.2);
  border-radius: 10px;
  position: relative;
  height: 100%;
}
.review-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
  color: #fff;
}
.review-author {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  color: #fff;
}

.logo-container {
  background-color: #224984;
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 1em;
  padding-bottom: 1em;
  border-bottom-right-radius: 32px;
}

.t-logo {
  height: 83px;
  width: 83px;
  object-fit: contain;
  border-radius: 41px;
  background-color: #fff;
}
