.rd-page-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background-color: #f9f9f9;
}
.rd-title {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-weight: bold;
  color: black;
  margin: 0px;
}
.rd-subtitle {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #595959;
  margin: 0px;
}
.rd-from-container {
  display: flex;
  flex-direction: column;
  padding: 36px 24px;
  background-color: #fff;
  border-radius: 8px;
  width: 500px;
  max-width: 100%;
  gap: 8px;
}
.login-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #224984;
  color: #fff;
  margin-top: 1em;
}
.login-form-btn:focus {
  outline: none;
}
.login-form-btn:hover {
  background-color: #2b5eaa;
}
.login-form-btn:disabled {
  background-color: #a5a5a5;
  color: #fff;
  border: none;
}
.rd-date-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
}
.rd-date-input {
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 12px;
  gap: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}
