.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 1em;
  text-align: center;
}
.promo-items-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  justify-items: center;
  align-items: start;
  align-self: center;
  transition: all 0.5s ease;
}

@media (max-width: 1100px) {
  .promo-items-container {
    grid-template-columns: 1fr;
  }
}
.promo-item {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 32px;
  max-width: 550px;
  height: 600px;
  justify-content: space-between;
}
.promo-item-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #000000;
}
.promo-item-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #535858;
}
.promo-item-image {
  max-width: 441px;
  max-height: 345px;
  object-fit: contain;
  align-self: center;
}

@media (max-width: 550px) {
  .promo-item {
    padding: 1em;
    height: auto;
  }

  .promo-item-image {
    max-width: 100%;
    max-height: 200px;
  }

  .promo-item-title {
    font-size: 20px;
  }

  .promo-item-description {
    font-size: 14px;
  }
}
