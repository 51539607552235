.modal-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.modal-title {
  margin: 0px;
  font-size: 22px;
  line-height: 26.4px;
  color: #000;
  font-weight: 400;
  align-self: center;
  text-align: center;
}
.modal-subtitle {
  margin: 0px;
  font-size: 14px;
  line-height: 16.8px;
  color: #595959;
  font-weight: 400;
  align-self: center;
  text-align: center;
}

.appointments-container {
  background-color: #f9f9f9;
  padding: 12px 16px;
}
.appointment-cards-container {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
}
.appointment-card-sm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #ffffff;
  padding: 8px;
  margin: 8px 0px;
}
.appointment-card-text {
  color: #000;
  font-size: 12px;
  line-height: 14px;
  margin: 0px;
}
.appointment-card-sub-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f9f9f9;
  padding: 4px 8px;
  min-width: 150px;
}
.appointment-cards-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.appointment-cards-container::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-radius: 10px;
}

.appointment-cards-container::-webkit-scrollbar-thumb {
  background: #595959;
  border-radius: 10px;
  cursor: pointer;
}

.appointment-cards-container::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}
.un-follow-buttons-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  gap: 1em;
  margin-top: 8px;
}
.cancel-un-follow-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #224984;
  border: 1px solid #224984;
  color: #ffffff;
  flex: 1;
}
.cancel-un-follow-btn:focus {
  outline: none;
}
.cancel-un-follow-btn:hover {
  background-color: #fff;
  border: 1px solid #187094;
  color: #187094;
}
.un-follow-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #ffffff;
  color: #dc3545;
  flex: 1;
  border: 1px solid #dc3545;
}
.un-follow-btn:focus {
  outline: none;
}
.un-follow-btn:hover {
  background-color: #dc3545;
  color: #fff;
}
.un-follow-btn:disabled {
  background-color: #a5a5a5;
  color: #fff;
  border: none;
}
.confirm-input-container {
  display: flex;
  flex: 1;
  margin: 8px 0px;
  flex-direction: column;
}
.confirm-unfollow-input {
  display: flex;
  flex: 1;
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
}
.confirm-unfollow-label {
  font-size: 16px;
  line-height: 19px;
  color: #000;
  margin: 8px 0px;
}

.generic-success-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #ffffff;
  border: 1px solid #224984;
  color: #224984;
  flex: 1;
}
.generic-success-btn:focus {
  outline: none;
}
.generic-success-btn:hover {
  background-color: #224984;
  color: #ffffff;
}
.generic-proceed-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #ffffff;
  color: #dc3545;
  border: 1px solid #dc3545;
  flex: 1;
}
.generic-proceed-btn:focus {
  outline: none;
}
.generic-proceed-btn:hover {
  background-color: #dc3545;
  color: #ffffff;
}
