@import 'zeplin-base.scss';

.FileUpload {
  height: auto;
  line-height: initial;
  font-family: $font-family;
  > input[type=file] {
    display: none;
  }
}
