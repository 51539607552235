.auth-page-container {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100%;
  background-color: #f9f9f9;
  flex-direction: column;
  padding: 2em;
  gap: 1em;
  justify-content: center;
}
.auth-page-title {
  font-family: Lato;
  font-size: 31px;
  font-weight: 400;
  line-height: 37.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  margin: 0px;
  align-self: center;
}
.auth-page-content-container {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 32px;
  box-shadow: 1px 3px 18px 0px #0000001a;
  align-self: center;
  gap: 4px;
  min-width: 350px;
}
.auth-page-tos-container {
  display: flex;
  align-items: center;
  gap: 1em;
  align-self: center;
}
.auth-page-horizontal-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
