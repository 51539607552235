.main_header {
  height: auto !important;
}
.main-header {
  background-color: transparent;
}
nav.navbariL .link {
  position: absolute;
  font-size: 18px;
  background-color: transparent;
  padding: 0px 0px 0px 130px;
}
nav.navbariL .link:hover,
.logout_navbar:hover {
  color: blue;
}
a.navbar-brand {
  margin: 0;
  padding: 0px 0px 0px 0px;
}
/* .navbar {
  background-color: white;
} */
.navbar div.collapse {
  background-color: transparent;
}
.myreplogo {
  width: 217px;
  height: 118px;
  object-fit: contain;
}

.nav-btn-container {
  display: flex;
  gap: 1em;
  align-items: center;
}

.navbar-toggler.collapsed {
  background-color: white;
}
.h-ca-link {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 34px !important;
  color: #25aae1 !important;
}
.h-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: #25aae1;
  color: #fff;
}
.h-login-btn:focus {
  outline: none;
}
.h-login-btn:hover {
  background-color: #187094;
  color: #fff;
}
.h-page-links {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 1em;
  justify-content: center;
}
.h-link {
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  color: #000;
  margin: 0px;
  cursor: pointer;
}
.h-link:hover {
  color: #4c4c4c;
}

@media (max-width: 780px) {
  .nav-btn-container {
    flex-direction: column;
    gap: 4px;
    padding: 1em 0px;
  }
  .h-login-btn {
    width: 80%;
  }
}
