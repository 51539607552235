.rc-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: #fff;
}
.rc-pic-container {
  display: flex;
  justify-content: space-around;
}
.rc-profile-pic {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
.rc-block-icon {
  cursor: pointer;
}
.rc-name-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
}
.rc-rep-name {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  margin: 0px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.rc-rep-company {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #808080;
  margin: 0px;
}
.rc-approval-status {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.rc-approval-text {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.rc-approval-label {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #595959;
  margin: 0px;
}
.rc-approved-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #25aae1;
}
.rc-blocked-container {
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  margin: 10px 0px;
  align-items: center;
}
.rc-unblock-btn {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.4px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: none;
  border: none;
  color: #3f77cd;
  margin: 8px 0px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.rc-unblock-btn:focus {
  outline: none;
}
.rc-unblock-btn:hover {
  transform: scale(1.05);
}
.rc-approval-btns-container {
  display: flex;
  padding: 4px;
  gap: 4px;
  border-radius: 32px;
  border: 1px solid #cccccc;
}
.rc-approval-btn {
  padding: 4px 1em;
  border-radius: 32px;
  background-color: #f9f9f9;
  border: none;
  outline: none;
  transition: all 0.5s ease;
  flex: 1;
  cursor: pointer;
  color: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: center;
  text-underline-position: from-font;
}
.rc-approval-btn:focus {
  outline: none;
}
.rc-approval-btn:hover {
  background-color: #f7f7f7;
}
.rc-approval-btn:disabled {
  cursor: no-drop;
}
.cm-title-container {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: center;
  color: #3f77cd;
  flex-direction: column;
}
.rc-restriction-bypass-btn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}
.rc-restriction-bypass-btn:focus {
  background: none;
  outline: none;
  border: none;
}
.rc-restriction-bypass-btn:hover {
  transform: scale(1.05);
}
.rc-restriction-bypass-btn:disabled {
  cursor: no-drop;
}
.rc-bypass-info-tooltip {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  text-align: center;
  width: 200px;
}
