.OnboardingForm {
  .TextInputContainer {
    margin-bottom: 20px;
  }

  .Button {
    width: 100%;
    border-radius: 5px;
  }

  h2 {
    font-size: 1.25rem !important;
    margin-top: 2rem;
  }
}
