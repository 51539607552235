@import "zeplin-base.scss";

.NavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 46px 30px 46px 30px;
  padding: 32px 24px;
  .Logo {
    width: 105px;
    height: 40px;
  }
  @include to($tablet) {
    .Logo {
      display: none;
    }
    background-color: white;
    padding: 24px 0px;
    z-index: 2;
    display: block;
    margin: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-top: 1px solid $dark;
  }

  // @include to($large-phone) {
  //   padding: 24px 0px;
  // }
}

nav.ClinicNav {
  display: flex;
  font-family: $font-family;
  > a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    width: 70px;
    > img {
      width: 32px;
      margin-bottom: 8px;
    }
    height: 70px;
    @include from($laptop) {
      margin-left: 40px;
    }
    &.--selected {
      color: $light;
    }
  }

  @include to($tablet) {
    justify-content: space-between;
    > a {
      > img {
        margin-bottom: 0;
      }
      margin: 0;
      height: auto;
    }
  }
  .dropdown-toggle::after {
    display: none !important; 
  }
}
