@import 'zeplin-base.scss';

.BasicForm {
  > form {
    width: 480px;
    @include to($tablet) {
      width: 350px;
    }
  }

//   .TextInputContainer {
//     min-width: 300px;
//     width: 100%;
//     .Input {
//       width: 100%;
//     }
//   }
}
