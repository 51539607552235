.banner-container {
  background: rgb(34, 73, 132);
  background: linear-gradient(56deg, rgba(34, 73, 132, 1) 0%, rgba(22, 47, 85, 1) 35%);
}
.boxes-container {
  display: grid;
  gap: 1em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  overflow: hidden;
}

.bg-box {
  border-radius: 32px;
  background: rgba(37, 170, 225, 0.02);
  backdrop-filter: blur(10px);
}
.heading-items-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 2em;
  z-index: 10;
}
.scheduling-heading {
  font-size: 65px;
  font-weight: 700;
  color: #fff;
  align-self: center;
  text-align: center;
  margin: 0px;
  line-height: 70px;
}
.scheduling-sub-content {
  background: linear-gradient(56deg, rgba(37, 170, 225, 1) 0%, rgba(150, 224, 255, 1) 35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari */
  background-clip: text;
  color: transparent; /* For other browsers */
}
.heading-subtext {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  align-self: center;
  text-align: center;
  margin: 0px;
  line-height: 28px;
}
.horizontal-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1em;
  padding: 0px 4em;
}

.banner-item-container {
  display: flex;
  flex-direction: column;
  padding: 1.2em;
  color: #fff;
  background: rgba(37, 170, 225, 0.1);
  border-radius: 10px;
  gap: 0.5em;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  min-height: 180px;

  /* Ensure the containers stretch equally */
  height: 100%;
}

.item-title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  line-height: 34px;
  color: #fff;
}

.item-description {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 19px;
  color: #fff;
  flex-grow: 1; /* Ensures equal spacing */
}

.icon-container {
  background-color: #25aae1;
  padding: 10px;
  border-radius: 22px;
  display: flex;
  align-self: flex-start;
  font-weight: 700;
}

@media (max-width: 700px) {
  .scheduling-heading {
    font-size: 40px; /* Adjust font size */
    line-height: 45px; /* Adjust line height */
  }

  .banner-item-container {
    max-width: 100%; /* Allow full width on small screens */
    flex: 1 1 calc(100% - 1em); /* Make items take full width and wrap */
  }
}
@media (max-width: 600px) {
  .horizontal-container {
    padding: 0px 1em;
  }
}
