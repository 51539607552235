.mini-rep-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 1em;
  border-radius: 8px;
  transition: all 0.5s ease;
}
.mini-rep-container:hover {
  background-color: #f9f9f9;
}
.header-nav-logo {
  height: 224px;
  width: 238px;
  object-fit: contain;
}
