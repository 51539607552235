@import "colors.scss";
@import "media.scss";

$standard-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
$font-family: "Avenir Next", "Lato", sans-serif;

a,
h1,
h2,
h3,
h4,
h5,
p,
ul,
li,
blockquote,
figure,
button {
  font-family: $font-family !important;
  color: $dark;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

strong {
  color: $dark;
}

a.Button,
button.Button,
label.Button {
  box-sizing: border-box;
  border: none;
  max-width: 100%;
  background-color: $dark;
  padding: 10px;
  border-radius: 5px;
  display: block;
  color: #fff;
  text-align: center;
  &.ButtonGray {
    color: $dark;
    background-color: #e8e8e8;
    &:hover {
      background-color: #a4a4a4;
      color: white;
    }
  }
  &.ButtonLight {
    color: $light;
    border: 2px solid $light;
    background-color: white;
    &:hover {
      color: white;
    }
  }
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: $light;
  }

  &:disabled {
    color: #a4a4a4;
    cursor: not-allowed;
    background-color: #e8e8e8;
    &:hover {
      background-color: #e8e8e8;
      color: #a4a4a4;
    }
  }
}

a.Link {
  color: $light !important;
}

// TODO move this to TextInput.scss
.TextInputContainer {
  label {
    pointer-events: none;
  }
}

.Input {
  box-sizing: border-box;
  width: 100%;
  color: $dark;
  padding: 28px 16px 10px 16px;
  font-weight: bold;
  border: 2px solid $dark;
  background-color: #fff;
  font-size: 16px;
  border-radius: 5px;

  &:focus {
    border-color: $light;
    box-shadow: $standard-box-shadow;
  }

  &::placeholder {
    font-style: italic;
    color: rgba(0, 0, 0, 0.24);
    font-weight: normal;
  }

  &:disabled {
    border: 2px solid gray;
    color: gray;
    background: rgb(231, 231, 231);
  }
}

.modal-content {
  padding: 20px;
}

.light {
  color: $light;
}

.MobileHeader {
  &.Header {
    display: none;
    @include to($tablet) {
      display: flex;
      margin: 32px;
    }
  }
}

.FullWidthModal {
  width: 90% !important; // regrettably necessary...
  max-width: 100% !important;
}

.Logo {
  display: block;
  width: 105px;
  margin: 32px;
}

.MobileLogo {
  display: none;
  @include to($tablet) {
    display: block;
    width: 105px;
    margin: 32px;
  }
}

.TopAndLeftLogo {
  display: block;
  @include from($tablet) {
    width: 105px;
    margin: 32px;
  }
  @include to($tablet) {
    width: 144px;
    margin: 12px auto;
  }
}

.QuickFacts {
  display: flex;
  justify-content: space-between;
  > div {
    > strong {
      display: block;
    }
    @include to ($tablet) {
            margin-right: 8px;
            margin-bottom: 16px;
        }
  }
    @include to ($tablet) {
        flex-wrap: wrap;
        }
}

.ResponsiveCardGrid {
  display: grid;
  row-gap: 24px;
  column-gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.notMobile {
  display: none;
  @include from($tablet) {
    display: block;
  }
}
