/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

/* .stripe_input {
  width: 100px;
}*/
.StripeElement {
  height: 40px;
  padding: 0px 0px 0px 12px;
  width: 75em;
  color: #32325d;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  box-shadow: none;
  -webkit-transition: none;
  transition: all 0.5s ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* * {
  font-family: "Helvetica Neue", Helvetica;
  font-size: 15px;
  font-variant: normal;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}
*/

.stripe_form {
  /* background: #e6ebf1; */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  /* margin-top: 3em; */
}

@media (min-width: 900px) {
  .stripe_form {
    flex-direction: row;
  }
}

form {
  width: 480px;
  margin: 20px 0;
}

@media screen and (max-width: 520px) {
  form {
    width: 100%;
  }

  .amount-options {
    display: flex;
    flex-direction: column;
  }
}

.btn-pay {
  display: inline-block;
}

.group {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 20px;
}

label {
  position: relative;
  color: #8898aa;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
}

.group label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}

label > span {
  width: 120px;
  text-align: right;
  margin-right: 30px;
}

.field {
  background: transparent;
  font-weight: 300;
  border: 0;
  color: #31325f;
  outline: none;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  cursor: text;
}

.field::-webkit-input-placeholder {
  color: #cfd7e0;
}

.field::-moz-placeholder {
  color: #cfd7e0;
}

/* 
button {
  float: left;
  display: block;
  background: #666ee8;
  color: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  line-height: 38px;
  outline: none;
} */

/* button:focus {
  background: #555abf;
}

button:active {
  background: #43458b;
} */

.outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 24px;
  text-align: center;
}

.success,
.error {
  display: none;
  font-size: 13px;
}

.success.visible,
.error.visible {
  display: inline;
}

.error {
  color: #e4584c;
}

.success {
  color: #666ee8;
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}

.press_once {
  margin-top: 20px;
  color: blue;
}

.inline-form {
  display: inline;
  vertical-align: middle;
}
