$medium-phone: 375px;
$large-phone: 425px;
$tablet: 576px;
$laptop: 768px;
$desktop: 1024px;
$large-desktop: 1440px;

@mixin to($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin from($min) {
  @media (min-width: $min) {
    @content;
  }
}

.to-laptop-hide {
  @include to($laptop) {
    display: none;
  }
}

