.c-image-bg {
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  align-items: center;
  /* align-self: center; */
  transition: all 0.5s ease;
  background-image: url("../../../assets/images/home/contactSectionBg.png");
  background-color: rgba(28, 65, 72, 0.75);
  background-blend-mode: darken;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  border-radius: 32px;
  padding: 4em 2em;
}

.c-left {
  display: flex;
  flex-direction: column;
  max-width: 336px;
}
.c-logo {
  width: 240px;
  height: 240px;
  object-fit: contain;
}
.c-description {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 28px;
}
.c-contact {
  display: flex;
  flex-direction: column;
}

.c-contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  color: #fff;
  text-decoration: none;
  gap: 8px;
}

.c-icon-container {
  background-color: #fff;
  color: #25aae1;
  border-radius: 18px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 4em;
}

.c-links-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-main-pages,
.c-helping-links {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.c-main-pages h3,
.c-helping-links h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  line-height: 28px;
  color: #fff;
}

.c-main-pages a,
.c-helping-links a {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  line-height: 28px;
  color: #fff;
}
.c-divider {
  width: 100%;
  height: 1px;
  background-color: #83949b;
  margin: 2em 0em;
}
.c-subscribe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-subscribe h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  line-height: 28px;
  color: #fff;
}

.c-subscribe p {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  line-height: 28px;
  color: #fff;
}

.c-input-container {
  display: flex;
  flex: 1;
  align-items: center;
  background: rgba(37, 170, 225, 0.2);
  backdrop-filter: blur(10px);
  padding: 0.5em 1em;
  border-radius: 32px;
  border: 1px solid #fff;
}
.c-subscribe-input {
  padding: 0em;
  border-radius: 20px;
  border: none;
  width: 240px;
  display: flex;
  flex: 1;
  background: none;
  color: #fff;
}
.c-subscribe-input::placeholder {
  color: #fff;
}
.c-subscribe-button {
  background-color: #25aae1;
  border: none;
  padding: 0.5em;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
}

.c-subscribe form {
  display: flex;
  align-items: center;
}
.c-footer-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0% 10%;
}
@media (max-width: 800px) {
  .c-image-bg {
    grid-template-columns: 1fr;
  }
  .c-footer-container {
    padding: 0% 1em;
  }
}
@media (max-width: 600px) {
  .c-footer-container {
    flex-direction: column;
    gap: 8px;
  }
  .c-image-bg {
    background-image: none;
    background: rgb(28, 65, 72);
    background: linear-gradient(56deg, rgba(28, 65, 72, 1) 0%, rgba(3, 32, 40, 1) 35%);
    background-blend-mode: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 600px;
    border-radius: 32px;
    padding: 4em 2em;
  }
}
.c-legal-text {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  line-height: 28px;
  color: #000;
}
