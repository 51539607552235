.faq-right-section {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}
.accordion-collapse-container {
  padding: 0px 32px;
}
.accordion-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px;
  padding: 20px 24px;
  width: 100%;
  cursor: pointer;
  margin: 10px 0px;
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
}
.accordion-toggle-btn:focus {
  outline: none;
  background-color: #fff;
  border: 1px solid #0000001a;
}
.accordion-toggle-btn:hover {
  outline: none;
  background-color: #fff;
  border: 1px solid #0000001a;
}
.accordion-question {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  line-height: 28px;
  margin: 0px;
  text-align: left;
}
.accordion-answer {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 28px;
  margin: 0px;
}
