.section-container {
  display: flex;
  padding: 4em 1em;
  flex-direction: column;
  gap: 3em;
  position: relative;
}
.header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  line-height: 34px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s ease;
}
.header-btn:focus {
  outline: none;
}
.header-btn:hover {
  background-color: #187094;
  color: #fff;
}
.btn-standard {
  background-color: #25aae1;
  color: #fff;
}

.btn-outlined {
  background-color: transparent;
  border: 1px solid #25aae1;
  color: #25aae1;
}
.description-pill-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #3f77cd;
  background-color: #ecf1fa;
  border-radius: 32px;
}
.section-heading {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #000;
  margin: 0px;
}
.section-sub-heading {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #595959;
  margin: 0px;
}
